 /* eslint-disable */
export default [
  {
    header: 'Administração',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Regionais',
    route: 'regionais',
    icon: 'MapPinIcon',
    resource: 'Admin',
  },
  {
    title: 'Agencias',
    route: 'agencias',
    icon: 'BriefcaseIcon',
    resource: 'Admin',
  },
  {
    title: 'Colaboradores',
    route: 'colaboradores',
    icon: 'UsersIcon',
    resource: 'Admin',
  },
  {
    title: 'Carteiras',
    route: 'carteiras',
    icon: 'CreditCardIcon',
    resource: 'Admin',
  },
  // {
  //   title: 'Produtos',
  //   route: 'produtos',
  //   icon: 'PackageIcon',
  // },
]
