 /* eslint-disable */
export default [
  {
    header: 'UAD',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'UAD',
    route: 'uads',
    icon: 'BriefcaseIcon',
    resource: 'Admin',
  },
  {
    title: 'Ações',
    route: 'uad_acaos',
    icon: 'BookmarkIcon',
    resource: 'Admin',
  },
]
