/* eslint-disable */
export default [
    {
      header: 'Configurações',
      resource: 'Admin',
      action: 'read',
    },
    {
      title: 'Historico',
      icon: 'ClockIcon',
      route: 'historicos',
      resource: 'Admin',
    },
    {
      title: 'Campanha',
      icon: 'ClipboardIcon',
      route: 'campanhas',
      resource: 'Admin',
    }
]
  