/* eslint-disable */
export default [
  {
    header: 'Ranking',
    action: 'read',
    resource: 'Ranking',
  },
  {
    title: 'Ranking Colaborador',
    route: 'rankingColaborador',
    icon: 'UserIcon',
    resource: 'Ranking',
  },
  {
    title: 'Próspera',
    icon: 'AwardIcon',
    tag: '3',
    tagVariant: 'light-success',
    children: [
      {
        title: 'Ranking PF',
        icon: 'UserIcon',
        route: 'prospera_pf',
        resource: 'Ranking',
        action: 'read',
      },
      {
        title: 'Ranking PJ',
        icon: 'BriefcaseIcon',
        route: 'prospera_pj',
        resource: 'Ranking',
        action: 'read',
      },
      {
        title: 'Ranking Agencia',
        icon: 'AwardIcon',
        route: 'prospera_agencia',
        resource: 'Ranking',
        action: 'read',
      },
    ],
  },

  {
    title: 'Desempenho',
    icon: 'ActivityIcon',
    tag: '3',
    tagVariant: 'light-success',
    children: [
      {
        title: 'Cooperativa',
        icon: 'AwardIcon',
        route: 'ranking_coop',
        resource: 'Ranking',
        action: 'read',
      },
      {
        title: 'Regional',
        icon: 'AwardIcon',
        route: 'ranking_regional',
        resource: 'Ranking',
        action: 'read',
      },
      {
        title: 'Agencia',
        icon: 'AwardIcon',
        route: 'ranking_porte',
        resource: 'Ranking',
        action: 'read',
      },
    ],
  },
  // {
  //   title: 'Bonificação',
  //   icon: 'DollarSignIcon',
  //   route: 'rankingBonificacao',
  //   resource: 'Ranking',
  // },
  {
    title: 'UAD',
    icon: 'BriefcaseIcon',
    route: 'ranking_uad',
    resource: 'Ranking',
  },

]
