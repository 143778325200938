/* eslint-disable */
export default [
    {
      header: 'Importação',
      action: 'read',
      resource: 'Admin',
    },
    {
      title: 'Importação Campanha',
      icon: 'UploadIcon',
      route: 'importacao_dinamica',
      resource: 'Admin',
    },
    {
      title: 'Importação Geral',
      icon: 'UploadIcon',
      route: 'importacao_geral',
      resource: 'Admin',
    }
]
  