/* eslint-disable */
import dashboard from "./dashboard"
import ranking from "./ranking"
import admin from "./admin"
import config from "./config"
import imports from "./imports"
import uad from "./uad"

export default [
  ...dashboard,
  ...ranking,
  ...admin,
  ...imports,
  ...uad,
  ...config
]
